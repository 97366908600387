@import '../../variables.scss';

@font-face {
  font-family: 'CharterBT';
  src: url('../../fontes/Charter/CharterBT.ttf'),
    url('../../fontes/Charter/CharterBT.ttf') format('truetype');
}

@font-face {
  font-family: 'CharterBlackBT';
  src: url('../../fontes/Charter/CharterBlackBT.ttf'),
    url('../../fontes/Charter/CharterBlackBT.ttf') format('truetype');
}

.artigos {
  max-width: 900px;
  margin: auto;

  h2,
  h6 {
    margin: 0;
    text-align: center;
    font-family: CharterBT;
    color: $primary-color;
  }
}

.not {
  height: 100%;
  margin: 10px;
  float: left;
  margin-top: 50px;
  background: $primary-inverse;
  transition-duration: 200ms;
}

.not:hover {
  transition-duration: 200ms;
  transform: scale(1.03);
}

.imagem {
  margin-top: 15px;
}

.textinho {
  padding: 10px;
}

.azin {
  max-width: 300px;
  display: inline-block;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .artigos {
    max-width: 300px;
  }
}
@media screen and (max-width: 1300px) {
  .artigos {
    max-width: 950px;
  }
}

@media screen and (max-width: 4000px) and (min-width: 1300px) {
  .artigos {
    max-width: 1300px;
    margin-right: 30px;
  }
}
