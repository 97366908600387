@import '../../variables.scss';

@font-face {
  font-family: 'charter';
  src: url('../../fontes/Charter/CharterBT.ttf'),
    url('../../fontes/Charter/CharterBT.ttf') format('truetype');
}

@font-face {
  font-family: 'vollkorn-bold';
  src: url('../../fontes/vollkorn/Vollkorn-Bold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Bold.ttf') format('truetype');
}

.footer {
  margin-top: 50px;
  border-top: 15px solid $primary-color;

  ul {
    margin-top: -10px;
    max-width: 584px;
  }

  li {
    margin: 10px;
    display: inline-block;
    list-style: none;
    cursor: pointer;
    font-family: vollkorn-bold;
  }

  a {
    outline: none;
  }
}

.subFooter {
  margin-top: 15px;
  margin-right: 0px;
  width: 100%;
  min-height: 450px;
  background: $primary-color;
  color: $primary-inverse;
  font-family: charter;
}

.rodape {
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding-top: 50px;
}

.iconFooter {
  fill: $primary-inverse;
}

.instaF,
.faceF {
  margin: 5px;
}

@media screen and (max-width: 700px) {
  .footer {
    margin-top: 395px;
  }
  .rodape {
    width: 405px;
  }
  .rodape ul {
    width: 355px;
  }
}

@media screen and (max-width: 420px) {
  .footer {
    margin-top: 25px;
    margin-bottom: -10px;
  }
  .rodape {
    width: 300px;
  }
  .rodape ul {
    width: 300px;
  }
}
