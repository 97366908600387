@import '../../variables.scss';

@font-face {
  font-family: 'vollkorn';
  src: url('../../fontes/vollkorn/Vollkorn-Regular.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'vollkorn-bold';
  src: url('../../fontes/vollkorn/Vollkorn-Bold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'vollkorn-semibold';
  src: url('../../fontes/vollkorn/Vollkorn-Semibold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Semibold.ttf') format('truetype');
}

.header {
  transition: background 0.5s linear;
  width: 100%;
  height: 95px;
  position: absolute;
  color: $primary-color;
  top: 0px;
  z-index: 99999;
  display: flex;
  margin: 0;
}
.img {
  transform: background 1s linear;
  background: url(../../images/logo2.png) 0 0 no-repeat;
  background-position: top left;
  background-size: 100px;
  height: 150px;
  width: 150px;
  margin-top: 10px;
  margin-left: 15%;
}
.fixoImg {
  transform: background 1s linear;
  background: url(../../images/logo.png) 0 0 no-repeat;
  background-position: top left;
  background-size: 100px;
  height: 150px;
  width: 150px;
}
.header {
  width: 101%;
  li {
    font-family: vollkorn-bold;
    margin: 10px;
    padding: 25px;
    list-style: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
      transition: background 0.2s linear;
      background: rgba(124, 124, 124, 0.295);
    }
  }

  ul {
    width: 805px;
    margin: auto;
    padding: 0;
  }
}

.ul {
  width: 805px;
  margin: auto;
}

.fixo {
  transition: background 0.5s linear;
  position: fixed;
  z-index: 9999999;
  background: $primary-color;
  color: $primary-inverse;
}

.opc {
  background: #eee0c5c7;
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 1;
}

.icon {
  margin: auto;
  height: 26px;
  width: 26px;
  fill: $primary-color;
}

.headerMobile {
  display: none;
}
.ulmobile {
  display: none;
}
.face,
.insta {
  margin: 20px;
}

.social {
  float: right;
  width: 132px;
  margin-top: 10px;
  margin-right: 7%;
}

.fixoIcon {
  fill: $primary-inverse;
}

a {
  outline: none;
}

// -----------------------------
.banner {
  width: 100%;
  min-height: 100%;
}

.banner-bg {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: -10px;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.banner-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.textindobanner {
  max-width: 700px;
  margin: auto;
  text-align: center;
  font-size: 80px;
  font-family: vollkorn-semibold;
  padding-bottom: 40px;
}

.bot {
  padding: 2px 10px;
  cursor: pointer;
  font: inherit;
  font-size: 13px;
  font-family: vollkorn;
  color: $primary-inverse;
  background-image: linear-gradient(
    to top,
    darken($primary-color, 5%),
    $primary-color
  );
  border: none;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

.subBot {
  padding: 3px;
  border: 3px solid $primary-color;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-bottom: 25px;
  margin-top: 25px;
  z-index: 999999;
}

.textButton {
  position: relative;
  height: 700px;
  width: 700px;
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .ul {
    display: none;
  }

  .social {
    display: none;
  }

  .headerMobile {
    display: block;
    position: absolute;
    z-index: 5;
    top: 30px;
    right: -80%;
    background-image: url('../../images/menubutton.png');
    background-size: 100%;
    background-position: top right;
    height: 35px;
    width: 35px;
  }

  .fixoMobile {
    background-image: url('../../images/menubutton1.png');
  }

  .menumobile {
    position: relative;
    left: -180px;
    display: block;
    margin-top: 50px;
    width: 230px;
    background: $primary-color;
    color: $primary-inverse;
  }

  .menumobile li {
    font-family: vollkorn-bold;
    margin: 10px;
    padding: 25px;
    width: 200px;
    list-style: none;
    display: block;
    cursor: pointer;
  }

  .banner-text {
    left: 20%;
    text-align: center;
    width: 500px;
    text-align: center;
    height: 300px;
    margin-top: 100px;
  }

  .banner-text p {
    font-size: 40px;
  }
}

@media screen and (max-width: 420px) {
  .banner-text {
    max-width: 350px;
    height: 180px;
    left: 5%;
    top: -25px;
    text-align: center;
    text-align: center;
  }

  .imgBanner {
    height: 300px;
  }

  .banner-text p {
    font-size: 20px;
  }

  .subBot {
    margin-top: -25px;
  }
  .header {
    width: 104%;
  }
}
