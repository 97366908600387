@import '../../variables.scss';

@font-face {
  font-family: 'CharterBT';
  src: url('../../fontes/Charter/CharterBT.ttf'),
    url('../../fontes/Charter/CharterBT.ttf') format('truetype');
}
@font-face {
  font-family: 'CharterBlackBT';
  src: url('../../fontes/Charter/CharterBlackBT.ttf'),
    url('../../fontes/Charter/CharterBlackBT.ttf') format('truetype');
}
@font-face {
  font-family: 'vollkorn-bold';
  src: url('../../fontes/vollkorn/Vollkorn-Bold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Bold.ttf') format('truetype');
}

.slick-arrow {
  width: 30px;
  height: 30px;
  &:before {
    color: #000;
    font-size: 30px;
  }
}
select {
  margin: 10px;
  width: 170px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 2px 2px 2px;
  border: none;
  color: $primary-inverse;
  padding-left: 10px;
  font-family: CharterBT;
  font-size: 12px;
  background: $primary-color url(../../images/br_down.png) no-repeat 150px
    center;
}
.select {
  width: 100%;
  margin: auto;
  margin-bottom: 45px;
  text-align: center;
}
.gradeInt {
  margin-bottom: -25px;
  width: 1300px;
  margin: auto;
}

.p {
  font-size: 24px;
  font-family: vollkorn-bold;
  color: $primary-color;
  width: 195px;
  margin: auto;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) and (max-width: 1500px) {
  .gradeInt {
    margin-top: -30px;
    margin-bottom: 20px;
    width: 1000px;
  }
}

@media screen and (min-width: 1501px) and (max-width: 2000px) {
  .gradeInt {
    margin-top: -10px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 2000px) {
  .gradeInt {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 801px) and (min-width: 420px) {
  .gradeInt {
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 420px) {
  .gradeInt {
    margin-top: -40px;
    width: 300px;
  }
}
