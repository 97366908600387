@import '../../variables.scss';

@font-face {
  font-family: 'vollkorn';
  src: url('../../fontes/vollkorn/Vollkorn-Regular.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'vollkorn-bold';
  src: url('../../fontes/vollkorn/Vollkorn-Bold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Bold.ttf') format('truetype');
}

#contato {
  margin-top: 70px;
}

.mapinhashow {
  height: 450px;
  border: 5px solid #0f5373;
  margin-top: 35px;
  margin-left: 25px;
}

.contatin {
  margin-right: 25px;
}

.contatoMapa {
  width: 100%;
}

.contatoMapaInt {
  max-width: 1590px;
  margin: auto;
  height: 450px;
}

.contact-title {
  padding: 30px 0 10px;
  margin: 0;
  text-align: center;
  font-size: 24px;
  font-family: vollkorn;
  color: $primary-color;
}

.enviar {
  text-align: center;
  padding-top: 15px;
}

@media screen and (max-width: 800px) {
  #contato {
    margin-top: 0px;
  }
  .mapinhashow {
    margin-left: 0px;
  }

  .contatin {
    margin-right: 0px;
  }
}
