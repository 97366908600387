@import '../../variables.scss';

@font-face {
  font-family: 'CharterBT';
  src: url('../../fontes/Charter/CharterBT.ttf'),
    url('../../fontes/Charter/CharterBT.ttf') format('truetype');
}
@font-face {
  font-family: 'CharterBlackBT';
  src: url('../../fontes/Charter/CharterBlackBT.ttf'),
    url('../../fontes/Charter/CharterBlackBT.ttf') format('truetype');
}

.produtos {
  width: 1300px;
  h3 {
    color: $primary-color;
    font-family: CharterBT;
    font-size: 15px;
    margin-top: -5px;
    margin-bottom: -25px;
  }
  h5 {
    color: $primary-color;
    font-family: CharterBT;
    font-size: 12px;
  }
}

.div1 {
  width: 250px;
  height: 350px;
  margin-left: 25px;
  margin-right: 25px;
}
.div2 {
  margin-top: -200px;
  width: 250px;
  height: 200px;
  background-color: #eee0c5;
}
.image {
  width: 200px;
  height: 250px;
  margin: auto;
}

.scale {
  transition-duration: 200ms;
}

.scale:hover {
  transform: scale(1.02);
  transition-duration: 200ms;
}

.slick-next {
  margin-right: -5px;
}

.slide {
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

@media screen and (min-width: 800px) and (max-width: 1500px) {
  .slide {
    max-width: 805px;
  }
  .produtos {
    width: 1000px;
  }
  .slick-next {
    margin-right: -25px;
  }
}

@media screen and (min-width: 1501px) and (max-width: 2000px) {
  .slide {
    max-width: 1200px;
  }
}

@media screen and (min-width: 2000px) {
  .slide {
    max-width: 1200px;
  }
}

@media screen and (max-width: 801px) and (min-width: 420px) {
  .slide {
    max-width: 550px;
  }
  .produtos {
    width: 700px;
  }
  .slick-next {
    margin-right: -25px;
  }
}

@media screen and (max-width: 420px) {
  .slide {
    max-width: 300px;
  }
  .produtos {
    width: 300px;
  }
}
