@import '../../variables.scss';

@font-face {
  font-family: 'charter';
  src: url('../../fontes/Charter/CharterBT.ttf'),
    url('../../fontes/Charter/CharterBT.ttf') format('truetype');
}

$arrows-size: 200px;
$arrows-margin: -25px;

.about {
  margin: -$arrows-size + ($arrows-margin * 2) 0 0;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: $arrows-size;
    background: center no-repeat;
    background-size: 100%;
  }

  &:before {
    margin-bottom: $arrows-margin;
    background-image: url('../../images/arrowup.png');
  }

  &:after {
    margin-top: $arrows-margin;
    background-image: url('../../images/arrowdown.png');
  }
}

.about-content {
  position: relative;
  padding: 50px 50px 25px 50px;
  margin: auto;
  margin-top: -70px;
  margin-bottom: -70px;
  font-family: charter;
  color: $primary-inverse;
  background-color: $primary-color;
}

.about p {
  width: 400px;
  margin: auto;
  text-align: left;
}

.about-center {
  max-width: 1500px;
  margin: auto;
}

.about-content h1 {
  padding: 0px;
  margin: 0px;
  position: relative;
  bottom: 260px;
}

.butao {
  padding: 2px 7px;
  cursor: pointer;
  font: inherit;
  font-size: 13px;
  font-family: vollkorn;
  color: $primary-color;
  background-image: linear-gradient(
    to top,
    darken($primary-inverse, 5%),
    $primary-inverse
  );
  border: none;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}
.subButao {
  padding: 3px;
  border: 3px solid $primary-inverse;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-left: -270px;
  margin-bottom: 25px;
}

@media screen and (min-width: 800px) and (max-width: 1500px) {
  .about-content h1 {
    bottom: 210px;
  }
}

@media screen and (min-width: 2000px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 2) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 230px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }
  }
}

@media screen and (max-width: 801px) and (min-width: 420px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 20) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 170px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }
  }
}

@media screen and (max-width: 420px) and (min-width: 380px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 15) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 130px;
    }
  }
}

@media screen and (max-width: 380px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 14) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 120px;
    }
  }
}

@media screen and (max-width: 380px) and (max-height: 815px) and (min-height: 750px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 20) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 120px;
    }
  }
}

@media screen and (max-width: 420px) and (max-height: 900px) and (min-height: 700px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 22) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 120px;
    }
  }
}
@media screen and (max-width: 365px) and (max-height: 740px) and (min-height: 650px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 17) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 120px;
    }
  }
  .banner-text {
    width: 320px;
  }
}

@media screen and (max-width: 365px) and (max-height: 640px) and (min-height: 550px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 13) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 120px;
    }
  }
  .banner-text {
    width: 320px;
  }
}

@media screen and (max-width: 415px) and (max-height: 850px) and (min-height: 750px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 20) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 158px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 300px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -220px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 120px;
    }
  }
  .banner-text {
    width: 370px;
  }
}
@media screen and (max-width: 320px) and (max-height: 570px) and (min-height: 300px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 11) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 100px;
    }
  }
  .banner-text {
    width: 290px;
    margin-top: 85px;
  }
}
@media screen and (max-width: 415px) and (min-width: 380px) and (max-height: 740px) and (min-height: 650px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 16) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 128px;
    }
  }
  .banner-text {
    width: 380px;
    margin-top: 100px;
  }
}
@media screen and (max-width: 370px) and (min-width: 350px) and (max-height: 720px) and (min-height: 650px) {
  .about {
    margin: -530px 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 113px;
    }
  }
  .banner-text {
    max-width: 380px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 360px) and (min-width: 330px) and (max-height: 700px) and (min-height: 500px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 14) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 100px;
    }
  }
}
@media screen and (max-width: 360px) and (min-width: 330px) and (max-height: 780px) and (min-height: 700px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 18.5) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 100px;
    }
    .banner-text {
      max-width: 290px;
      margin-top: 100px;
    }
  }
}
@media screen and (max-width: 360px) and (max-height: 650px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 8) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 100px;
    }
  }
}
@media screen and (max-width: 400px) and (min-width: 365px) and (max-height: 700px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 13) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 115px;
    }
  }
}
@media screen and (max-width: 375px) and (min-width: 250px) and (max-height: 560px) {
  .about {
    margin: -$arrows-size + ($arrows-margin * 8) 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-height: 150px;
      background: center no-repeat;
      background-size: 100%;
    }

    &:before {
      margin-bottom: $arrows-margin;
      background-image: url('../../images/arrowup.png');
    }

    &:after {
      margin-top: $arrows-margin;
      background-image: url('../../images/arrowdown.png');
    }

    .about {
      width: 100%;
    }

    .about-content {
      width: 100%;
    }

    .about-content p {
      width: 250px;
      margin: auto;
      text-align: left;
    }

    .subButao {
      margin-left: -165px;
    }

    .about-content h1 {
      padding: 0px;
      margin: 0px;
      position: relative;
      bottom: 115px;
    }
  }
  .banner-text {
    max-width: 340px;
    margin-top: 100px;
  }
}
