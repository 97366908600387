@import '../../variables.scss';

@font-face {
  font-family: 'vollkorn-bold';
  src: url('../../fontes/vollkorn/Vollkorn-Bold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Bold.ttf') format('truetype');
}

.brasilmap {
  width: 100%;
  margin: auto;
  margin-top: -80px;
  margin-bottom: 10px;
  height: 710px;
}
.nomeestado {
  color: $primary-inverse;
  margin-bottom: 10px;
}

.state {
  cursor: default;
  fill: #eebc69;
}
.corfill {
  fill: #f6f0d9;
  transition: 0.2s fill linear;
}
.corfill:hover {
  transition: 0.2s fill linear;
  fill: #d4d2c6;
}

.corpreta {
  fill: #eebc69;
}

.subbrasilmap {
  width: 100%;
  min-height: 500px;
  padding-bottom: 40px;
  background: #d9a54a;
}

.barraCima {
  margin-bottom: 50px;
  border-top: 15px solid #d9a54a;
}
.barraBaixo {
  margin-top: 15px;
  border-bottom: 15px solid #d9a54a;
}

.brasilmapint {
  width: 100%;
  margin: auto;
}
.popup {
  display: none;
  margin: auto;
  z-index: 999999;
}

.fixado2 {
  transition: display 1s;
  display: inline-block;
  position: absolute;
  top: 150px;
  left: 50px;
}

.fixado4 {
  transition: display 1s;
  display: inline-block;
  position: absolute;
  top: 250px;
  left: -170px;
}

.fixado11 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 55px;
  right: 230px;
}

.fixado9 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 55px;
  right: 115px;
}

.fixado8 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 55px;
  right: 75px;
}

.fixado7 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 25px;
  right: 15px;
}

.fixado27 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 30px;
  right: -20px;
}

.fixado6 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 10px;
  right: -20px;
}
.fixado4 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 50px;
  left: 100px;
}

.fixado5 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 70px;
  left: 125px;
}

.fixado26 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 160px;
  right: 135px;
}

.fixado22 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 280px;
  right: 150px;
}

.fixado {
  transition: display 1s;
  display: block;
  position: relative;
  top: 155px;
}

.fixado3 {
  transition: display 1s;
  display: block;
  position: relative;
  top: 155px;
}
.fixado31 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 170px;
  right: 250px;
}
.fixado28 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 200px;
  right: 170px;
}
.fixado29 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 110px;
  right: 150px;
}
.fixado30 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 260px;
  right: 70px;
}
.fixado32 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 400px;
  right: 210px;
}
.fixado33 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 350px;
  right: 180px;
}
.fixado34 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 310px;
  right: 190px;
}
.fixado35 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 270px;
  right: 240px;
}
.fixado36 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 250px;
  right: 100px;
}
.fixado38 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 230px;
  right: 40px;
}
.fixado39 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 80px;
  right: -10px;
}
.fixado40 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 110px;
  right: 350px;
}
.fixado41 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: -70px;
  right: 210px;
}
.fixado42 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: 70px;
  right: 450px;
}
.fixado43 {
  transition: display 1s;
  display: block;
  position: absolute;
  top: -65px;
  right: 340px;
}

.h1mapa {
  padding-top: 40px;
  margin-top: -35px;
  margin-bottom: 40px;
  color: $primary-color;
  text-align: center;
  font-family: vollkorn-bold;
  font-size: 24px;
  padding-left: 15px;
}

.mapadobrasil {
  margin: auto;
  width: 600px;
  margin: auto;
  z-index: 50;
}

.mapabrasil svg {
  width: 600px;
}

.mapabrasil {
  margin: auto;
  width: 600px;
  position: relative;
}
.popup img {
  position: absolute;
  left: 180px;
  top: -5px;
  transform: rotate(90deg);
}

.mapabrasilconte {
  margin: auto;
  width: 498px;
  position: relative;
  bottom: 450px;
  left: 200px;
}

.mapabrasilconteazul {
  width: 400px;
  min-height: 115px;
  padding: 10px;
  background: $primary-color;
  color: $primary-inverse;
  font-family: vollkorn-bold;
}

#svg-map path {
  fill: #eebc69;
}

#svg-map a .cor {
  fill: #f6f0d9 !important;
}

#svg-map {
  background: transparent;
}

#svg-map a {
  text-decoration: none;
}
#svg-map a:hover .cor {
  cursor: pointer;
  text-decoration: none;
}
#svg-map a:hover .cor {
  fill: #dbd8c9 !important;
}

@media screen and (max-width: 420px) {
  .mapabrasil svg {
    width: 300px;
  }
  .mapabrasilconte {
    margin: auto;
    width: 300px;
    position: relative;
    bottom: 450px;
    left: 200px;
  }
  .mapabrasilconteazul {
    width: 200px;
    min-height: 115px;
    padding: 10px;
    background: $primary-color;
    color: $primary-inverse;
    font-family: vollkorn-bold;
  }
  .popup img {
    position: absolute;
    left: 100px;
    top: -5px;
    transform: rotate(90deg);
  }
  .mapabrasil {
    margin: auto;
    width: 330px;
    position: relative;
    top: -150px;
  }
  .subbrasilmap {
    height: 70%;
  }
  .fixado2 {
    transition: display 1s;
    display: inline-block;
    position: absolute;
    top: -10px;
    left: -80px;
  }

  .fixado4 {
    transition: display 1s;
    display: inline-block;
    position: absolute;
    top: 250px;
    left: -170px;
  }

  .fixado11 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -50px;
    right: 270px;
  }

  .fixado9 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -50px;
    right: 210px;
  }

  .fixado8 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -50px;
    right: 200px;
  }

  .fixado7 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -70px;
    right: 160px;
  }

  .fixado27 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -70px;
    right: 150px;
  }

  .fixado6 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -70px;
    right: 140px;
  }
  .fixado4 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -55px;
    left: -50px;
  }

  .fixado5 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -40px;
    left: -40px;
  }

  .fixado26 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 0px;
    right: 223px;
  }

  .fixado22 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 70px;
    right: 225px;
  }
  .fixado31 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 5px;
    right: 280px;
  }
  .fixado28 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 20px;
    right: 240px;
  }
  .fixado29 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -27px;
    right: 230px;
  }
  .fixado30 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 50px;
    right: 180px;
  }
  .fixado32 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 130px;
    right: 260px;
  }
  .fixado33 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 100px;
    right: 240px;
  }
  .fixado34 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 80px;
    right: 250px;
  }
  .fixado35 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 60px;
    right: 275px;
  }
  .fixado36 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 45px;
    right: 200px;
  }
  .fixado38 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: 35px;
    right: 170px;
  }
  .fixado39 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -35px;
    right: 150px;
  }
  .fixado40 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -30px;
    right: 330px;
  }
  .fixado41 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -115px;
    right: 260px;
  }
  .fixado42 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -50px;
    right: 330px;
  }
  .fixado42.popup img {
    position: absolute;
    left: 30px;
    top: -5px;
    transform: rotate(90deg);
  }
  .fixado43 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -120px;
    right: 330px;
  }
  .fixado44 {
    transition: display 1s;
    display: block;
    position: absolute;
    top: -60px;
    right: 330px;
  }
  .brasilmap {
    margin-bottom: -165px;
  }
}
