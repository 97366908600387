@import '../../variables.scss';

.form-control {
  display: block;
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  color: inherit;
  border: rgba(204, 204, 204, 0) 2px solid;
  background: #eee0c5;
  border-radius: 4px;
  -webkit-appearance: none;

  @at-root textarea#{&} {
    resize: none;
  }

  &:focus {
    outline: none;
    border-color: $primary-color;
  }

  &:invalid {
    box-shadow: none;
  }

  &::placeholder {
    font-family: vollkorn;
    color: $primary-color;
    font-size: 12px;
    opacity: 1;
  }
}
