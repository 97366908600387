@import '../../variables.scss';

@font-face {
  font-family: 'vollkorn';
  src: url('../../fontes/vollkorn/Vollkorn-Regular.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'vollkorn-bold';
  src: url('../../fontes/vollkorn/Vollkorn-Bold.ttf'),
    url('../../fontes/vollkorn/Vollkorn-Bold.ttf') format('truetype');
}

.blog {
  width: 100%;
  margin: auto;
  margin-top: 50px;
  border-top: 15px solid $primary-color;
  border-bottom: 15px solid $primary-color;
  margin-top: -40px;
  padding-bottom: 20px;
}
.subBlog {
  min-height: 610px;
  width: 100%;
  background: $primary-color;
}

.intBlog {
  background: $primary-color;
  max-width: 1580px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.titulo {
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-family: vollkorn-bold;
  color: $primary-inverse;
  padding-top: 25px;
}
.botao {
  padding: 2px 10px;
  cursor: pointer;
  font: inherit;
  font-size: 13px;
  font-family: vollkorn;
  color: $primary-color;
  background-image: linear-gradient(
    to top,
    darken($primary-inverse, 5%),
    $primary-inverse
  );
  border: none;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}
.subBotao {
  padding: 3px;
  border: 3px solid $primary-inverse;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  position: relative;
  left: 48%;
  top: -10px;
  margin-bottom: 70px;
  margin-top: 25px;
}

@media screen and (max-width: 801px) and (min-width: 420px) {
  .subBotao {
    left: 46%;
  }
}

@media screen and (max-width: 420px) {
  .subBotao {
    left: 40%;
  }
}
