@import '../../variables.scss';

.button {
  padding: 2px 10px;
  cursor: pointer;
  font: inherit;
  font-family: vollkorn;
  color: $primary-inverse;
  background-image: linear-gradient(
    to top,
    darken($primary-color, 5%),
    $primary-color
  );
  border: none;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}
.subButton {
  padding: 3px;
  border: 3px solid $primary-color;
  display: inline-block;

  font-size: 13px;
  cursor: pointer;
}
