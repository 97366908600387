$font-size-base: 16px;

$primary-color: #0f5373;
$primary-inverse: #faf0da;

$secondary-color: #0f5373;
$secondary-inverse: #faf0da;

$text-text: #0f5373;
$background-color: #faf0da;

$title-color-default: $secondary-color;
$title-color-inverse: $secondary-inverse;

$social-background: $secondary-color;
$social-icon: $secondary-inverse;
